import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from '@/router'
import * as Sentry from '@sentry/vue'
import VueTippy from 'vue-tippy'
import Toast from 'vue-toastification'
import App from './App.vue'
import i18n from './i18n'
import toastOptions from './utils/toastOptions'
import setupConfiguration from './utils/setupConfiguration'
import http, { axios } from './http'
import VueAxios from 'vue-axios'
import StripePlugin from './plugins/stripe.js'
import AblyPlugin from './plugins/ably.js'
import veProgress from 'vue-ellipse-progress'
import VueTelInput from 'vue-tel-input'
import 'animate.css'
import './assets/scss/base.scss'
import './assets/scss/auth.scss'
import './assets/scss/tailwind.scss'
import 'vue-tel-input/vue-tel-input.css'
import 'vue-toastification/dist/index.css'
import { version } from '../package.json'
import { addCollection } from '@iconify/vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { createHead } from '@unhead/vue'
import localIconsData from 'virtual:local-icons'

const date = import.meta.env.VITE_GIT_BUILD_DATA || null
const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production'
const classViewURL = import.meta.env.VITE_CLASSIC_VIEW_URL || null

setupConfiguration()

const app = createApp(App)

const head = createHead()

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,

  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.airalo\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app
  .use(veProgress)
  .use(createPinia())
  .use(http)
  .use(Toast, toastOptions)
  .use(VueTippy)
  .use(VueAxios, axios)
  .use(StripePlugin)
  .use(router)
  .use(i18n)
  .use(VueTelInput)
  .use(AblyPlugin, {
    apiKey: import.meta.env.VITE_ABLY_AUTH_KEY,
  })
  .use(() => addCollection(localIconsData))
  .use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID || 'GTM-UNDEFINED',
      queryParams: {
        gtm_auth: import.meta.env.VITE_GTM_AUTH || '',
        gtm_preview: import.meta.env.VITE_GTM_PREVIEW || '',
        gtm_cookies_win: 'x',
      },
      loadScript: true,
      vueRouter: router,
      debug: !isProduction,
    })
  )
  .use(head)
  .provide('axios', app.config.globalProperties.axios)
  .provide('buildMeta', {
    version,
    date,
    isProduction,
    classViewURL,
  })
  .mount('#app')

import { ref, onMounted, toValue, type MaybeRefOrGetter, type Ref } from 'vue'
import { watchDebounced } from '@vueuse/core'
import {
  type AxiosRequestConfig,
  type AxiosResponseHeaders,
  type RawAxiosResponseHeaders,
} from 'axios'
import { axios } from '@/http'
import type { ErrorData } from '@/api/types/useApiDataError'

interface RefetchConfig<K> {
  watch: Ref<K> | (() => K)
  callback?: () => void
}

interface UseApiDataOptions<T, R, K> {
  done?: () => void
  transformer?: (
    data: T,
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  ) => R
  immediate?: boolean
  removeEmptyValuesFromRequest?: boolean
  params?: MaybeRefOrGetter<Record<string, unknown>>
  config?: MaybeRefOrGetter<AxiosRequestConfig>
  refetch?: RefetchConfig<K>
}

type UseApiDataPayload = Record<string, unknown> | FormData | Array<unknown>

export const removeEmptyValues = (params?: UseApiDataPayload) =>
  Object.entries(params ?? {}).reduce(
    (acc, [key, value]) => {
      if (value || typeof value === 'number' || typeof value === 'boolean') {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, unknown>
  )

export function useApiData<T = unknown, R = T, K = unknown>(
  url: MaybeRefOrGetter<string>,
  options: UseApiDataOptions<T, R, K> = {}
) {
  const {
    transformer,
    immediate = true,
    removeEmptyValuesFromRequest = true,
    params,
    done,
    config,
    refetch,
  } = options
  const data = ref<R | null>(null)
  const loading = ref(false)
  type ErrorEvent = Error & ErrorData
  const error = ref<ErrorEvent | null>(null)
  let executeCounter = 0

  const resetData = () => {
    error.value = null
    loading.value = false
    data.value = null
  }

  const fetchData = async () => {
    executeCounter += 1
    const currentExecuteCounter = executeCounter

    resetData()
    loading.value = true
    try {
      const response = await axios.get(toValue(url), {
        params: removeEmptyValuesFromRequest
          ? removeEmptyValues(toValue(params))
          : toValue(params),
        ...toValue(config),
      })
      data.value = transformer
        ? transformer(response.data, response.headers)
        : response.data

      done && done()
    } catch (err) {
      error.value = err as Error
    } finally {
      if (currentExecuteCounter === executeCounter) {
        loading.value = false
      }
    }
  }

  const updateData = async (itemData?: MaybeRefOrGetter<UseApiDataPayload>) => {
    executeCounter += 1
    const currentExecuteCounter = executeCounter

    resetData()
    loading.value = true
    try {
      const response = await axios.put(
        toValue(url),
        removeEmptyValuesFromRequest
          ? removeEmptyValues(toValue(itemData))
          : toValue(itemData),
        toValue(config)
      )
      data.value = transformer
        ? transformer(response.data, response.headers)
        : response.data

      done && done()
    } catch (err) {
      error.value = err as Error
    } finally {
      if (currentExecuteCounter === executeCounter) {
        loading.value = false
      }
    }
  }

  const createData = async (itemData?: MaybeRefOrGetter<UseApiDataPayload>) => {
    executeCounter += 1
    const currentExecuteCounter = executeCounter

    resetData()
    loading.value = true
    try {
      const response = await axios.post(
        toValue(url),
        removeEmptyValuesFromRequest
          ? removeEmptyValues(toValue(itemData))
          : toValue(itemData),
        toValue(config)
      )
      data.value = transformer
        ? transformer(response.data, response.headers)
        : response.data

      done && done()
    } catch (err) {
      error.value = err as Error
    } finally {
      if (currentExecuteCounter === executeCounter) {
        loading.value = false
      }
    }
  }

  const deleteData = async () => {
    executeCounter += 1
    const currentExecuteCounter = executeCounter

    loading.value = true
    try {
      const response = await axios.delete(toValue(url), {
        params: removeEmptyValuesFromRequest
          ? removeEmptyValues(toValue(params))
          : toValue(params),
        ...toValue(config),
      })
      data.value = transformer
        ? transformer(response.data, response.headers)
        : response.data

      done && done()
    } catch (err) {
      error.value = err as Error
    } finally {
      if (currentExecuteCounter === executeCounter) {
        loading.value = false
      }
    }
  }

  const patchData = async (itemData?: MaybeRefOrGetter<UseApiDataPayload>) => {
    executeCounter += 1
    const currentExecuteCounter = executeCounter

    loading.value = true
    try {
      const response = await axios.patch(
        toValue(url),
        removeEmptyValuesFromRequest
          ? removeEmptyValues(toValue(itemData))
          : toValue(itemData),
        toValue(config)
      )
      data.value = transformer
        ? transformer(response.data, response.headers)
        : response.data

      done && done()
    } catch (err) {
      error.value = err as Error
    } finally {
      if (currentExecuteCounter === executeCounter) {
        loading.value = false
      }
    }
  }

  if (immediate) {
    onMounted(fetchData)
  }

  if (refetch) {
    watchDebounced(
      refetch.watch,
      () => {
        if (refetch.callback) {
          refetch.callback()
        } else {
          fetchData()
        }
      },
      { debounce: 300, maxWait: 800 }
    )
  }

  return {
    data,
    loading,
    error,
    fetchData,
    createData,
    updateData,
    patchData,
    deleteData,
  }
}

import { defineStore } from 'pinia'
import { computed } from 'vue'
import { type CompanyData } from '@/models/companyModels'

export const useCompanyStore = defineStore('company', () => {
  const pricingModel = computed(() => companyData.value?.pricing_model ?? null)
  const discountPercentage = computed(() => companyData.value?.discount_percentage ?? 0)
  const isNetPrice = computed(() => pricingModel.value === 'net_pricing')

  const { data: companyData, loading: companyDataLoading, fetchData: initCompany } =
    useApiData<CompanyData>(`/company/v1/company`, {
      immediate: true,
    })

  return {
    companyData,
    companyDataLoading,
    isNetPrice,
    pricingModel,
    discountPercentage,
    initCompany,
  }
})
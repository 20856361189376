<template>
  <div
    class="max-w-7xl h-full w-full grid gap-y-3 items-center px-6 pt-16 md:pt-2 md:pb-6 overflow-hidden"
  >
    <ButtonBackArrow
      data-testid="onboarding-back-button"
      @click="onBackButtonClick"
    />

    <div class="flex flex-col gap-2 place-items-center sm:mb-8 text-gray-800">
      <h2 class="text-inherit">{{ $t('partnerOnboardingTitle') }}</h2>

      <span>{{ $t('partnerOnboardingSubtitle') }}</span>
    </div>

    <TrailTabGroup
      v-model="selectedTab"
      :tabs="tabs"
      tab-list-class="max-w-none w-screen md:w-auto gap-x-2 -mx-6 grid justify-items-stretch grid-flow-col justify-start h-full gap-y-4"
      tab-trigger-class="bg-trail-surface-tertiary-normal rounded-full px-4 !border !border-trail-border-normal data-[state=active]:!border-trail-border-highlight"
      tab-triggers-container-class="gap-x-2 px-6 overflow-x-auto scrollbar-hide"
      tab-indicator-class="!border-0"
      tab-indicator-line-class="!hidden"
      class="mt-4 mb-6 md:mb-0"
    >
      <template #airalo-for-business>
        <OnboardingTab
          :title="t('onboardingAiraloForBusinessTitle')"
          :points="t('onboardingAiraloForBusinessPoints')"
          :button-action-text="t('onboardingAiraloForBusinessActionButtonText')"
          stream-type-prop="corporate"
          link="/sign-up/onboarding/corporate"
          illustration="/partners/partner-1.svg"
          regular-list
        />
      </template>

      <template #resellers>
        <OnboardingTab
          :title="t('onboardingResellersTitle')"
          :points="t('onboardingResellersPoints')"
          :button-action-text="t('onboardingResellersActionButtonText')"
          stream-type-prop="reseller"
          link="/sign-up/onboarding/reseller"
          illustration="/partners/partner-2.svg"
          regular-list
        />
      </template>

      <template #brand-partners>
        <OnboardingTab
          :title="t('onboardingBrandPartnersTitle')"
          :points="t('onboardingBrandPartnersPoints')"
          :button-action-text="t('onboardingBrandPartnersActionButtonText')"
          stream-type-prop="brand"
          link="/sign-up/onboarding/brand"
          illustration="/partners/partner-4.svg"
          regular-list
        />
      </template>

      <template #affiliates>
        <OnboardingTab
          :title="t('onboardingAffiliatesTitle')"
          :points="t('onboardingAffiliatesPoints')"
          :button-action-text="t('onboardingAffiliatesActionButtonText')"
          :link="affiliateLink"
          stream-type-prop="affiliate"
          is-link-external
          illustration="/partners/partner-3.svg"
          regular-list
        />
      </template>

      <template #influencers>
        <OnboardingTab
          :title="t('onboardingInfluencersTitle')"
          :points="t('onboardingInfluencersPoints')"
          :button-action-text="t('onboardingInfluencersActionButtonText')"
          stream-type-prop="paid_influencer"
          link="/sign-up/onboarding/paid-influencers"
          illustration="/partners/partner-6.svg"
          regular-list
        />
      </template>
    </TrailTabGroup>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { definePage, useRouter } from 'vue-router/auto'
import { affiliateLink } from '@/constant/onboarding'

definePage({
  name: 'onboarding',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding',
})

const { t } = useI18n({ useScope: 'global' })

const selectedTab = ref('airalo-for-business')

const tabs = [
  {
    value: 'airalo-for-business',
    label: t('partnerOnboardingBusinessLabel'),
  },
  {
    value: 'resellers',
    label: t('partnerOnboardingResellerLabel'),
  },
  {
    value: 'brand-partners',
    label: t('partnerOnboardingBrandPartners'),
  },
  {
    value: 'affiliates',
    label: t('partnerOnboardingAffiliates'),
  },
  {
    value: 'influencers',
    label: t('partnerOnboardingInfluencers'),
  },
]

const router = useRouter()

const onBackButtonClick = () => {
  router.back()
}
</script>

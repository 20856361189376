<template>
  <div class="relative" data-testid="sign-up-page">
    <LandingHeaderBar
      class="sticky top-0"
      data-testid="header-bar"
      type="sign-up"
    />

    <div data-testid="main-content">
      <ValuePropositionSectionHero
        class="px-6 pb-12 md:pb-0"
        data-testid="hero-section"
      />

      <ValuePropositionTrustedBy
        :img-list="trustedByImageList"
        :img-width="100"
        :img-height="30"
        :animation-time="15"
      />

      <ValuePropositionPrograms data-testid="programs-section" />

      <ValuePropositionFeatures class="px-6" data-testid="features-section" />

      <ValuePropositionSteps class="px-6" data-testid="steps-section" />

      <ValuePropositionReadyAction
        class="px-6"
        data-testid="ready-action-section"
      />

      <ValuePropositionFooter class="px-6" data-testid="footer-section" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'

definePage({
  meta: { requiresAuth: false },
  name: 'sign-up',
})

const { t } = useI18n({ useScope: 'global' })

useSeoMeta({
  ogDescription: t('seo.og.sign-up.description'),
  ogTitle: t('seo.og.sign-up.title'),
  ogImage: 'https://partners.airalo.com/partners/og-hero-sign-up.jpg',
})

import { type TImageProps } from '@/components/ValueProposition/ValuePropositionTrustedBy.vue'

const trustedByImageList = computed<TImageProps[]>(() => {
  const images = Object.keys(
    import.meta.glob([
      '#root/public/partners/trusted-by/*.png',
      '#root/public/partners/trusted-by/*.svg',
    ])
  ).map((img, index) => ({
    img: img.replace('/public', ''),
    alt: `img ${index + 1}`,
    position: index + 1,
  }))

  return images
})
</script>

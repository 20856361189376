<template>
  <div class="flex flex-col gap-6">
    <ManageBulkESimsShare
      v-if="shareBulkSIMs"
      :selectedBulkSims="selectedBulkSIMs"
      @hideBulkShare="hideBulkShare"
    />

    <ManageBulkESims
      v-else-if="showBulkSIMs"
      :selected-bulk-sims="selectedBulkSIMs"
      @hide-bulk-assign="hideBulkAssign"
      @show-bulk-share="showBulkShare"
      @fetch-esims="fetchSimList"
    />

    <template v-else>
      <div class="flex justify-between place-items-end">
        <ManageEsimsHeader
          v-model:search-query="searchQuery"
          v-model:date-range="dateRange"
          v-model:status-filter="filters.byAllocationStatus"
          v-model:origin-filter="filters.byOrigin"
          v-model:attention-filters-selected="filters.attentionFilters"
          data-testid="manageEsimsHeader"
          @clear-panel-filters="clearPanelFilters"
          @clear-all-filters="clearAllFilters"
        />

        <div v-if="!loading" class="flex flex-row items-center gap-4 pb-4">
          <strong class="text-trail-body3">{{
            $t('numberOfEsimsSelected', {
              selectedNumber: selectedRows.length,
              totalNumber: totalItems,
            })
          }}</strong>

          <ManageEsimsTableBulkActions
            :esims="selectedRows"
            :esims-total="totalItems"
            :export-selected-limit="exportSelectedLimit"
            :export-all-limit="exportAllLimit"
            @export-selected-esims="exportSelectedEsims"
            @export-all-esims="exportAllEsims"
            @show-bulk-assign="showBulkAssign"
            @show-bulk-share="showBulkShare"
          />
        </div>
      </div>

      <div class="esims-list">
        <TrailBanner
          v-if="exportEsimsLoading"
          class="mb-2"
          data-testid="esimsExportAllLoading"
          show-icon
          type="in_progress"
        >
          {{ $t('loading') }}
        </TrailBanner>

        <TrailBanner
          v-if="selectedRows.length > exportSelectedLimit"
          data-testid="esimsSelectedLimitExceeded"
          show-icon
          type="warning"
        >
          {{ $t('exportSelectedEsimsLimit', { number: exportSelectedLimit }) }}
        </TrailBanner>

        <ManageEsimsTable
          v-model:selectedRows="selectedRows"
          v-model:page="currentPage"
          :rows="simList"
          :is-loading="loading"
          :total-items="totalItems"
          @patch-sim="handlePatchSim"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { definePage } from 'vue-router/auto'
import { useRoute } from 'vue-router/auto'
import { cloneDeep, isEqual } from 'lodash'
import { useStorage } from '@vueuse/core'
import { useApiData } from '@/composables/useApiData'
import { transformSimListResponse } from '@/api/transforms/simsTransforms'
import type { SimListItem } from '@/models/simModels'
import router from '@/router'
import { useI18n } from 'vue-i18n'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import { downloadFile } from '@/utils/downloadFile'

definePage({
  name: 'manage-esims',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Sim\\Models\\Sim'],
  },
})

const { t } = useI18n({ useScope: 'global' })
const { positive, negative } = useTrailToast()

const route = useRoute()
const searchQuery = ref('')
const currentPage = ref(1)
const dateRange = ref('')
const showBulkSIMs = ref(false)
const shareBulkSIMs = ref(false)
const selectedBulkSIMs = ref<SimListItem[]>([])
const selectedRows = ref<[]>([])
const exportSelectedLimit = ref(10000)
const exportAllLimit = ref(10000)

const defaultFilters = {
  byAllocationStatus: '',
  byOrigin: '',
  attentionFilters: [],
}

const filters = ref(cloneDeep(defaultFilters))

const exportSelectedEsims = async (esims: SimListItem[]) => {
  if (esims.length > exportSelectedLimit.value) {
    return
  }

  await exportEsims({ ids: esims.map((item: SimListItem) => item.id) })
}

const exportAllEsims = async () => {
  if (totalItems.value && totalItems.value > exportAllLimit.value) {
    return
  }

  await exportEsims(getQueryParams(true))
}

const addBrandRedirectSelectedSim = useStorage<{ page: number }>(
  'addBrandRedirectSelectedSim',
  { page: 1 }
)
const addBrandRedirectSelectedSims = useStorage<SimListItem[]>(
  'addBrandRedirectSelectedSims',
  []
)
const checkRedirectFromAddBrand = () => {
  const { redirectToShareBulkEsims, redirectToShareEsim } = route?.query || {}

  if (redirectToShareBulkEsims) {
    shareBulkSIMs.value = true
    selectedBulkSIMs.value = addBrandRedirectSelectedSims.value
    addBrandRedirectSelectedSims.value = []
  } else if (redirectToShareEsim) {
    currentPage.value = addBrandRedirectSelectedSim.value.page
  }

  if (currentPage.value === 1) {
    fetchSimList()
  }
}

const checkICCIDWithQuery = () => {
  const iccid = route?.query?.iccid

  if (iccid) {
    searchQuery.value = iccid.toString()
    router.push('/manage-esims')
  }
}

const showBulkAssign = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  showBulkSIMs.value = true
}

const showBulkShare = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  shareBulkSIMs.value = true
}

const hideBulkAssign = () => {
  showBulkSIMs.value = false
}

const hideBulkShare = () => {
  shareBulkSIMs.value = false
}

const totalItems = ref<number>()
const {
  data: simListResponse,
  fetchData: fetchSimList,
  loading,
} = useApiData(`manage/v1/sims`, {
  transformer: transformSimListResponse,
  params: () => getQueryParams(false),
  done: () => {
    totalItems.value = simListResponse.value?.meta?.total
  },
  immediate: false,
})

const {
  loading: exportEsimsLoading,
  error: exportError,
  createData: exportEsims,
} = useApiData(() => `/manage/v1/sims/export`, {
  immediate: false,
  config: {
    responseType: 'blob',
  },
  transformer: (data: Blob, headers) => {
    downloadFile(data, headers, `Esims`)
  },
  done: () => {
    positive({
      content: `${t('exportEsimsSuccess')}`,
      timeout: 5000,
    })
  },
})

const getQueryParams = (onlyFilters: boolean): Record<string, unknown> => {
  if (onlyFilters) {
    return {
      filter: {
        search: searchQuery.value,
        created_at: dateRange.value.replace(' to ', ' - '),
        type: filters?.value?.byAllocationStatus,
        origin: filters.value.byOrigin,
        attention: filters.value.attentionFilters,
      },
    }
  }
  return {
    'filter[search]': searchQuery.value,
    'filter[created_at]': dateRange.value.replace(' to ', ' - '),
    'filter[type]': filters?.value?.byAllocationStatus,
    'filter[origin]': filters.value.byOrigin,
    'filter[attention]': filters.value.attentionFilters,
    page: currentPage.value,
    limit: 25,
  }
}

const simList = computed(() => simListResponse.value?.data ?? [])

const handlePatchSim = (rowId: number, data: Partial<SimListItem>) => {
  const simListItem = simList.value.find(({ id }) => id === rowId)!
  simListItem.endUser = data.endUser
  simListItem.companyBrandId = data.companyBrandId
  simListItem.companyBrandAlias = data.companyBrandAlias
}

const clearPanelFilters = () => {
  if (!isEqual(filters.value, defaultFilters))
    filters.value = cloneDeep(defaultFilters)
}

const clearAllFilters = () => {
  filters.value = cloneDeep(defaultFilters)
  searchQuery.value = ''
  dateRange.value = ''
}

onMounted(() => {
  checkICCIDWithQuery()
  checkRedirectFromAddBrand()
})

watch(exportError, () => {
  negative({
    content: t('shareModalDownloadError'),
    timeout: 2000,
  })
})

watch(
  [searchQuery, dateRange, filters],
  () => {
    currentPage.value = 1
  },
  { deep: true }
)

watch(
  [searchQuery, dateRange, filters, currentPage],
  () => {
    fetchSimList()
  },
  { deep: true }
)
</script>

<template>
  <main
    class="bg-trail-background-secondary w-full h-full"
    data-testid="app-wrapper"
  >
    <div class="flex items-center gap-20 px-6 py-4">
      <div class="flex gap-4 h-11">
        <router-link
          ref="goToHomeRouterRef"
          data-testid="sidebarGoToHomeRouter"
          :to="{ name: 'home' }"
        >
          <img src="/logo.svg" alt="logo" width="121" class="h-9" />
        </router-link>
      </div>

      <div class="flex text-nowrap">
        <p class="text-trail-title5 text-trail-text-title-normal">
          {{ $t('headerTitleTestSSOConfiguration') }}
        </p>
      </div>
    </div>

    <div
      class="flex justify-center items-center h-full p-6"
      data-testid="page-content"
    >
      <div data-testid="page-container">
        <div class="flex gap-8 flex-col items-center max-w-128">
          <SystemErrorImage v-if="!isSuccess" />

          <SystemSuccessImage v-else />

          <div class="grid gap-2">
            <h1 class="text-trail-display2 text-trail-text-display-normal">
              {{
                !isSuccess
                  ? $t('testSSOSamlConfigurationFailedTitle')
                  : $t('testSSOSamlConfigurationSuccessTitle')
              }}
            </h1>

            <p class="text-trail-body2 text-trail-text-body-normal">
              {{
                !isSuccess
                  ? $t('testSSOSamlConfigurationFailedDescription')
                  : $t('testSSOSamlConfigurationSuccessDescription')
              }}
            </p>

            <TrailBanner
              v-if="!isSuccess && route?.query?.message"
              class="mt-4"
              type="negative"
              show-icon
              icon="heroicons-exclamation-circle"
              data-testid="saml-configuration-test-fail-banner"
            >
              {{ route?.query?.message }}
            </TrailBanner>

            <TrailButton
              class="w-full mt-4"
              variant="secondary"
              @click="$router.push('/home')"
            >
              {{ $t('testSSOSamlConfigurationCtaButton') }}
            </TrailButton>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { definePage, useRoute } from 'vue-router/auto'
import SystemErrorImage from '@/assets/images/system-error-image.svg?component'
import SystemSuccessImage from '@/assets/images/system-success-image.svg?component'

definePage({
  meta: { requiresAuth: true },
})

const route = useRoute()
const isSuccess = computed(() => route.query.success === '1')
</script>

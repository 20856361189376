<template>
  <div class="grid gap-4">
    <UsersAddBulkTableView
      v-if="showAddBulkUsers"
      :bulkUsers="bulkUsers"
      @hideBulkUsers="showAddBulkUsers = false"
      @openBulkAddUsersModal="showUserAddBulkModal = true"
      @removeBulkUserItem="removeBulkUserItem"
      @fetchUsers="fetchData"
    />

    <template v-else>
      <BillToOrganizationBanner
        v-if="isCorporateModeOn && canViewSpendingSettings"
        :loading="companyDataLoading"
        :company-data="companyData"
      />

      <h3 class="text-trail-title2 text-trail-text-title-normal">
        {{ $t('userPageTitle') }}
        <span v-if="paginationData?.total">({{ paginationData.total }})</span>
      </h3>

      <div class="grid gap-4">
        <div
          class="flex items-center z-30 justify-between"
          :class="{
            '!items-start flex-col gap-4': width < 850,
          }"
        >
          <div class="flex gap-4">
            <TableSearchInput
              :recently-visited-storage-key="recentlyVisitedStorageKey"
              search-placeholder="userTableSearchPlaceholder"
              @search-changed="onSearchChanged"
            />

            <UserFilters @filters-changed="onFiltersChanged" />
          </div>

          <UserForm
            v-if="permissionsStore.canAddUser"
            :fetch-users="fetchData"
            :selected-users="selectedRows"
            :isSSOEnabled="isSSOEnabled"
            :company-bill-to-org="companyData?.bill_to_organization"
            @clear-selected-users="clearSelectedUsers"
            @fill-bulk-add-user="fillBulkAddUser"
          />
        </div>

        <TrailBanner
          v-if="isSSOEnabled"
          class="mb-2"
          type="warning"
          data-testid="sso-banner"
        >
          {{ $t('ssoBannerText') }}
        </TrailBanner>
      </div>

      <UsersTable
        v-model:selectedRows="selectedRows"
        :rows="userList"
        :is-loading="loading"
        :searchQuery="searchQuery"
        :pagination="paginationData"
        :company-bill-to-org="companyData?.bill_to_organization"
        @pagination-changed="pageChanged"
        @update:reload-table="fetchData"
      />
    </template>

    <UserAddBulkModal
      v-if="showUserAddBulkModal"
      :show="showUserAddBulkModal"
      @close="showUserAddBulkModal = false"
      @fill-bulk-user="fillBulkUser"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { definePage } from 'vue-router/auto'
import { useApiData } from '@/composables/useApiData'
import { useSSOConfiguration } from '@/composables/sso/use-sso-configuration'
import type { UserAddBulkDataType, UserFilterType } from '@/models/userModels'
import { usePermissionsStore } from '@/stores/permissions'
import { transformUserListResponse } from '@/api/transforms/userTransforms.ts'
import { useWindowSize } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth'
import { useFeatureFlagsStore } from '@/stores/feature-flags.ts'
import { storeToRefs } from 'pinia'
import type { CompanyData } from '@/models/companyModels.ts'

definePage({
  name: 'users',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\User\\Models\\User'],
  },
})

const { canViewSpendingSettings } = storeToRefs(usePermissionsStore())
const bulkUsers = ref<UserAddBulkDataType[]>([])
const selectedRows = ref<[]>([])
const { isSSOEnabled } = useSSOConfiguration()
const searchQuery = ref('')
const currentPage = ref(1)
const showAddBulkUsers = ref(false)
const urlQuery = ref({})
const { width } = useWindowSize()

const recentlyVisitedStorageKey = 'usersRecentlyVisitedStorageKey'
const permissionsStore = usePermissionsStore()
const showUserAddBulkModal = ref(false)

const authStore = useAuthStore()
const featureFlagsStore = useFeatureFlagsStore()
const isCorporateModeOn = computed(
  () => featureFlagsStore?.corporateMode && authStore?.isOnlyCorporateStream
)

const { data: companyData, loading: companyDataLoading } =
  useApiData<CompanyData>(`/company/v1/company`, {
    immediate: true,
  })

const {
  data: userListResponse,
  fetchData,
  loading,
} = useApiData('/user/v1/users', {
  transformer: transformUserListResponse,
  params: () => ({
    'filter[search]': searchQuery.value,
    ...urlQuery.value,
    page: currentPage.value,
    limit: 25,
  }),
  immediate: true,
})

const pageChanged = (page: number) => {
  selectedRows.value = []
  currentPage.value = page
  fetchData()
}

const clearSelectedUsers = (isFetchData: boolean = true) => {
  selectedRows.value = []

  if (isFetchData) fetchData()
}
const fillBulkUser = (data: UserAddBulkDataType[]) => {
  const mergedData = [...bulkUsers.value, ...data]
  bulkUsers.value = mergedData.map((item, key) => ({
    ...item,
    key,
  }))
}

const removeBulkUserItem = (key: number) => {
  bulkUsers.value = bulkUsers.value.filter((item) => item.key !== key)
}

const fillBulkAddUser = (data: UserAddBulkDataType[]) => {
  showAddBulkUsers.value = true
  bulkUsers.value = data
}

const onSearchChanged = (search: string) => {
  selectedRows.value = []
  searchQuery.value = search
  currentPage.value = 1
  fetchData()
}

const onFiltersChanged = (filter: UserFilterType) => {
  urlQuery.value = { filter }
  selectedRows.value = []
  fetchData()
}

const userList = computed(() => userListResponse.value?.data ?? [])
const paginationData = computed(() => userListResponse.value?.meta)
</script>
